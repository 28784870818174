section {
  display: flex;
  flex-direction: column;
  padding: 72px 12px 12px;

  &.section {
    &.section-align-center {
      justify-content: center;
      align-items: center;
    }

    &.section-span {
      min-height: 100vh;
    }

    h1,
    h2 {
      color: color("theme-darker");
    }
    h3 {
      color: color("theme-dark");
    }
  }

  &.section-dark {
    background-color: color("theme-darker");
    color: color("theme-white");
    h1,
    h2 {
      color: color("theme-lighter");
    }
    h3 {
      color: color("theme-light");
    }
  }
}
