@import "../../assets/theme/fonts.scss";
@import "../../assets/theme/colors.scss";
@import "../../assets/theme/breakpoints.scss";

.app-navigation {
  z-index: 1;
  max-height: 64px;
  min-height: 64px;
  max-width: 100%;
  min-width: 100%;
  padding: 12px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: color("theme-white");

  .app-navigation-brand-icon {
    max-width: 40px;
    max-height: 40px;
    display: block;
    animation: app-navigation-brand-icon infinite 4s linear;
  }

  .app-navigation-menu-icon {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    align-items: center;
    margin-left: auto;

    .app-navigation-menu-icon-bar {
      background-color: color("theme-dark");
      min-height: 4px;
      min-width: 32px;
      max-height: 4px;
      max-width: 32px;
      margin: 4px 0px;
      border-radius: 4px;
      position: relative;

      transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &.state-active {
      background-color: color("theme-black");

      .app-navigation-menu-icon-bar {
        background-color: color("theme-light");

        &.top {
          transform: rotate(-45deg);
          top: 10px;
        }
        &.middle {
          visibility: hidden;
        }
        &.bottom {
          transform: rotate(45deg);
          top: -6px;
        }
      }
    }
  }

  .app-navigation-menu {
    display: none;
    height: 100vh;
    background: color("theme-white");
    width: 100vw;
    position: absolute;
    padding: 12px;
    margin-left: auto;
    top: 0;
    left: 0;
    z-index: -1;

    .app-navigation-menu-item {
      text-decoration: none;
      text-align: center;
      width: 100%;
      padding: 12px;
      margin: 8px;
      margin-left: auto;
      font-family: font("code");
      font-size: 44px;
      color: color("theme-dark");

      &:hover {
        background-color: color("theme-darker");
        color: color("theme-white");
      }
    }

    &.show {
      display: flex;
      flex-direction: column;
      min-height: auto;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: breakpoint("md")) {
  .app-navigation {
    &.state-active {
      background-color: color("theme-white");
      min-width: 100%;
      min-height: 100vh;
      display: block;

      .app-navigation-menu {
        padding: 64px 40px;

        .app-navigation-menu-item {
          display: inline-block;
          min-width: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: breakpoint("md")) {
  .app-navigation {
    .app-navigation-menu-icon {
      display: none;
    }
    .app-navigation-menu {
      display: flex;
      height: auto;
      width: auto;
      position: relative;
      z-index: 0;
      flex-direction: row;
      align-items: center;
      .app-navigation-menu-item {
        padding: 4px 8px;
        margin: 0;
      }
    }
  }
}

$navigation-menu-item-font-size-map: (
  "xs": 44px,
  "sm": 36px,
  "md": 22px,
  "lg": 20px,
  "xl": 20px,
);

@each $name, $font-size in $navigation-menu-item-font-size-map {
  @media only screen and (min-width: breakpoint($name)) {
    .app-navigation {
      .app-navigation-menu {
        .app-navigation-menu-item {
          font-size: $font-size;
        }
      }
    }
  }
}

@keyframes app-navigation-brand-icon {
  0% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}
