@use "sass:color";

$colors: (
  "theme-black": #1e1c23,
  "theme-darker": #1c2541,
  "theme-dark": #3a506b,
  "theme-light": #69aab5,
  "theme-lighter": #6fffe9,
  "theme-white": #f9f4f5,
);

$opacities: (0, 25, 50, 75, 100);

@function color($shade) {
  @return map-get($map: $colors, $key: $shade);
}

@each $color_name, $color_value in $colors {
  .color- {
    &#{$color_name} {
      color: $color_value !important;
    }
  }
  .background-color- {
    &#{$color_name} {
      background-color: $color_value !important;

      @each $opacity_value in $opacities {
        &-#{$opacity_value} {
          background-color: color.change(
            $color_value,
            $alpha: calc($opacity_value / 100)
          ) !important;
        }
      }
    }
  }
  .border-color- {
    &#{$color_name} {
      border-color: $color_value !important;
    }
  }
}
