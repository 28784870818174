@import "../../assets/theme/colors.scss";

#blog-view {
  font-family: Georgia, "Times New Roman", Times, serif !important;

  table {
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;

    tr {
      td,
      th {
        padding: 8px 16px;
      }
    }

    thead tr {
      background-color: color("theme-darker");
      color: color("theme-white");
    }
  }
}
