.plain-link,
.plain-link:active,
.plain-link:focus,
.plain-link:visited {
  text-decoration: none;
  color: inherit;
}

.highlight-link,
.highlight-link:active,
.highlight-link:focus,
.highlight-link:visited {
  text-decoration: color("theme-light") dotted underline 3px;
  color: color("theme-dark");
}

.section-dark {
  .highlight-link,
  .highlight-link:active,
  .highlight-link:focus,
  .highlight-link:visited {
    text-decoration: color("theme-white") dotted underline 3px;
    color: color("theme-lighter");
  }
}
