.iframe-container {
  width: 100%;
  height: auto;
  display: flex;

  iframe {
    max-width: 100%;
    border: 0;
  }
}
