@font-face {
  font-family: "Inconsolata";
  src: url("../fonts/Inconsolata.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter.ttf");
}

$font-mono: "Inconsolata", monospace;
$font-sans: "Inter", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

$fonts: (
  "code": $font-mono,
  "theme": $font-sans,
);

@function font($key) {
  @return map-get($map: $fonts, $key: $key);
}

@each $font_name, $font_value in $fonts {
  .font- {
    &#{$font_name} {
      font-family: $font_value;
    }
  }
}
