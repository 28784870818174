@import "../../assets/theme/colors.scss";
@import "../../assets/theme/typography.scss";
@import "../../assets/theme/breakpoints.scss";

.carousel {
  margin: 0 20px;

  .carousel-item-container {
    display: flex;
    scrollbar-width: none;
    overflow-x: scroll;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    .carousel-item {
      min-width: 100%;
      display: flex;
      margin: 32px 64px;

      & > * {
        min-width: 100%;
        margin: 0;
      }
    }
  }

  .carousel-interface {
    min-width: 100%;
    min-height: 100%;
    display: flex;
    padding: 0;

    .carousel-controls {
      display: flex;

      .carousel-item-button {
        cursor: pointer;
        min-height: 40px;
        min-width: 40px;
        padding: 0;

        background: none;
        border: none;
        border-radius: 8px;
        margin-left: 16px;

        position: relative;
        font-size: font-size("xs");
        color: color("theme-light");
        display: block;

        &:hover {
          background-color: color("theme-black");
          color: color("theme-white");
          border: 2px solid color("theme-light");
        }
      }
    }

    .carousel-state {
      display: flex;
      width: 100%;
      margin-left: auto;
      font-size: font-size("xs");
      user-select: none;

      p {
        margin: auto 0;
        display: block;
      }
    }
  }
}

@media only screen and (min-width: breakpoint("md")) {
  .carousel {
    margin: 0;
  }
}
