@import "../../assets/theme/breakpoints.scss";
@import "../../assets/theme/colors.scss";

section#about-section {
  #about-section-intro-card {
    display: block;

    #about-section-intro-card-profile-picture {
      background-color: color("theme-light");
      border-radius: 50%;
      overflow: hidden;
      margin: auto;
      width: 60%;

      img.profile-picture {
        width: 100%;
        height: auto;
        margin: auto;
        display: block;
      }
    }
    #about-section-intro-card-details {
      text-align: center;

      h1.brand-name {
        font-size: 12vw;
      }
    }
  }
}

section#resume-section {
  .flex-grid-item {
    border: 2px solid color("theme-darker");
    background-color: color("theme-white");
    margin: 32px 0;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    img.resume-preview {
      min-width: 100%;
      image-rendering: pixelated;
    }
  }
}

$brand-name-font-size-map: (
  "xs": 64px,
  "sm": 48px,
  "md": 56px,
  "lg": 56px,
  "xl": 64px,
);

@each $name, $font-size in $brand-name-font-size-map {
  @media only screen and (min-width: breakpoint($name)) {
    section#about-section {
      #about-section-intro-card {
        #about-section-intro-card-details {
          h1.brand-name {
            font-size: $font-size !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: breakpoint("md")) {
  section#about-section {
    #about-section-intro-card {
      width: -moz-available;
      width: -webkit-fill-available;
      width: stretch;

      #about-section-intro-card-profile-picture {
        width: 420px;
        max-width: 420px;
        img.profile-picture {
          border: none;
        }
      }
      #about-section-intro-card-details {
        text-align: left;
        justify-content: left;
        align-items: start;
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: breakpoint("lg")) {
  section#about-section {
    #about-section-intro-card {
      display: flex;
      #about-section-intro-card-profile-picture {
        img.profile-picture {
          max-width: 400px;
        }
      }
      #about-section-intro-card-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
