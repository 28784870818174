$size-scalar-list: (
  "0": 0,
  "1": 1px,
  "2": 2px,
  "4": 4px,
  "8": 8px,
  "12": 12px,
  "16": 16px,
  "32": 32px,
  "64": 64px,
  "auto": auto,
);

@each $scalar_name, $scalar_size in $size-scalar-list {
  .padding-scalar- {
    &#{$scalar_name} {
      padding: $scalar_size;
    }
    &top-,
    &y- {
      &#{$scalar_name} {
        padding-top: $scalar_size;
      }
    }
    &bottom-,
    &y- {
      &#{$scalar_name} {
        padding-bottom: $scalar_size;
      }
    }
    &left-,
    &x- {
      &#{$scalar_name} {
        padding-left: $scalar_size;
      }
    }
    &right-,
    &x- {
      &#{$scalar_name} {
        padding-right: $scalar_size;
      }
    }
  }
  .margin-scalar- {
    &#{$scalar_name} {
      margin: $scalar_size;
    }
    &top-,
    &y- {
      &#{$scalar_name} {
        margin-top: $scalar_size;
      }
    }
    &bottom-,
    &y- {
      &#{$scalar_name} {
        margin-bottom: $scalar_size;
      }
    }
    &left-,
    &x- {
      &#{$scalar_name} {
        margin-left: $scalar_size;
      }
    }
    &right-,
    &x- {
      &#{$scalar_name} {
        margin-right: $scalar_size;
      }
    }
  }
}
