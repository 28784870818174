@import "./fonts.scss";
@import "./colors.scss";
@import "./breakpoints.scss";
@import "./display.scss";
@import "./spacing.scss";
@import "./typography.scss";
@import "./links.scss";
@import "./markup/index.scss";

* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: color("theme-dark") color("theme-darker");
}

body {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100%;
  max-width: 100%;

  background-color: color("theme-white");
  color: color("theme-black");
  font-family: font("theme");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main#root {
  min-width: 100%;
  max-width: 100%;
  padding-top: 64px;
}

@-moz-document url-prefix() {
  * {
    scroll-behavior: smooth;
  }
}
