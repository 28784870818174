@import "../colors.scss";
.container {
  display: block;
  width: 100%;
}

$container-width-map: (
  "xs": 100%,
  "sm": 100%,
  "md": 80%,
  "lg": 80%,
  "xl": 70%,
);

@each $breakpoint, $container-width in $container-width-map {
  @media only screen and (min-width: breakpoint($breakpoint)) {
    .container {
      width: $container-width;
    }
  }
}
