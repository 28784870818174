$alignment-list: (center, left, right, justify);

@each $alignment in $alignment-list {
  .text-align- {
    &#{$alignment} {
      text-align: $alignment;
    }
  }
}

$font-size-list: (
  "body": 22px,
  "tiny": 14px,
  "xxs": 16px,
  "xs": 18px,
  "sm": 20px,
  "md": 22px,
  "lg": 24px,
  "xl": 28px,
  "xxl": 32px,
);

@each $font-size-name, $font-size in $font-size-list {
  .font-size-#{$font-size-name} {
    font-size: $font-size;
  }
}

@function font-size($size) {
  @return map-get($map: $font-size-list, $key: $size);
}
