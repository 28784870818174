@import "../colors.scss";

.card-row {
  display: block;
  justify-content: space-between;
}

.card {
  border: 2px solid color("theme-light");
  border-radius: 8px;
  overflow: hidden;
  margin: 32px 20px;

  display: flex;
  flex-direction: column;

  .card-image {
    width: 100%;
  }
  .card-header,
  .card-body,
  .card-footer {
    padding: 16px;
  }
  .card-header {
    background-color: color("theme-light");
  }
  .card-body {
    white-space: pre-wrap;
  }
  .card-footer {
    background-color: color("theme-dark");
    margin-top: auto;
  }
}

$card-row-map: (
  "xs": (
    "card-row-display": block,
    "card-width": auto,
    "card-margin": 32px 20px,
  ),
  "sm": (
    "card-row-display": block,
    "card-width": auto,
    "card-margin": 32px 20px,
  ),
  "md": (
    "card-row-display": flex,
    "card-width": 32%,
    "card-margin": 0,
  ),
  "lg": (
    "card-row-display": flex,
    "card-width": 32%,
    "card-margin": 0,
  ),
  "xl": (
    "card-row-display": flex,
    "card-width": 32%,
    "card-margin": 0,
  ),
);

@each $breakpoint, $card-row-data in $card-row-map {
  @media only screen and (min-width: breakpoint($breakpoint)) {
    .card-row {
      display: map-get($map: $card-row-data, $key: "card-row-display");
      .card {
        width: map-get($map: $card-row-data, $key: "card-width");
        margin: map-get($map: $card-row-data, $key: "card-margin");
      }
    }
  }
}
