@import "../../assets/theme/breakpoints.scss";

.flex-grid {
  .flex-grid-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .flex-grid-item {
      .flex-grid-item-header {
        min-width: fit-content;
        display: block;

        & > * {
          max-width: 100%;
        }
      }
      flex: 1;
      max-width: 100%;
    }
  }
}

@each $breakpoint, $_ in $breakpoints {
  @media only screen and (max-width: breakpoint($breakpoint)) {
    .flex-grid {
      .flex-grid-row.flex-grid-row-break-#{$breakpoint} {
        display: block;
      }
    }
  }
}
